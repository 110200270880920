import { Stack } from '@components/Stack'
import styled from 'styled-components'

export const Container = styled(Stack).attrs({
    justifyContent: 'space-between',
    alignItems: 'center',
    direction: 'row'
})`
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    max-width: 1280px;
    height: 182px;
    overflow-x: scroll;
    overflow-y: hidden;
    border-radius: 4px;
    &::-webkit-scrollbar {
        display: none;
    }
    margin-top: 33px;
`

import dynamic from 'next/dynamic'
import { DepartmentSliderProps } from '../types'
import * as Styled from './styles'

const CardCarousel = dynamic(
    () =>
        import('@components/CardCarousel').then(module => module.CardCarousel),
    { ssr: true }
)

const Department = dynamic(
    () => import('../Department').then(module => module.Department),
    { ssr: true }
)

export const DepartmentSliderDesktopView = ({
    departments
}: DepartmentSliderProps): JSX.Element => {
    if (!departments) return <></>

    return (
        <Styled.Container>
            <CardCarousel spacing={16} buttonPosition="between" buttonSize={40}>
                {departments.map((department, index) => (
                    <Department key={index} department={department} />
                ))}
            </CardCarousel>
        </Styled.Container>
    )
}
